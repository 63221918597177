<script lang="ts">
    import Images from "../../components/Images/Images.svelte";
    import Videos from "../../components/Videos/Videos.svelte";
    import { getImageUrl } from "../../api";
    import { formatDate } from "../../helpers";
    import type { News } from "../../types/News";
    import type { PartialItem } from "@directus/sdk";
    import Files from "../../components/Files/NovedadesFiles.svelte"

    export let bgWhite = true;
    export let news: PartialItem<News>;
</script>

{#if news}
    <div class="{bgWhite ? 'bg-white shadow-md' : ''} rounded-lg gap-4 p-4 pt-0 inline-block my-5">
        <div class="col-span-2 px-8 py-4 data-block">
            {#if news.imagen}
                <div class="w-100 h-100 float-left inline-block float-left pr-5">
                    <img src="{getImageUrl(news.imagen,{w:400,h:300})}" alt="{news.titulo + '-image'}">
                </div>
            {/if}
            <h2 class="mt-0">{news.titulo}</h2>
            <div class="mb-3">
                <span class="text-sm font-light text-gray-600">{formatDate(news.fecha)} 
                    {#if news.proyecto}
                        <span class="news-detail-project">{news.proyecto.nombre}</span>
                    {/if}
                    {#if news.categoria}
                        <span class="news-detail-category">{news.categoria.nombre}</span>
                    {/if}
                </span>
            </div>
            {#if news.contenido}
                <div class="mb-3">                
                    {@html news.contenido}                   
                </div>
            {/if}
            <div class="clear-both"></div>
        </div>
        <Videos id={news.id} field= {'novedad'} bgWhite={bgWhite}/>
        <Images id={news.id} field= {'novedad'} bgWhite={bgWhite}/>
        <Files {news} bgWhite={bgWhite}/>
    </div>
{/if}