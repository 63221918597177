<script lang="ts">
    import {directusSDK} from "../../../api";
    import Calendar from "../../../components/project_sheet/Calendar/Calendar.svelte";
    import TechnicalSheet from "../../../components/project_sheet/TechnicalSheet/TechnicalSheet.svelte";
    import Images from "../../../components/Images/Images.svelte";
    import Videos from "../../../components/Videos/Videos.svelte";
    import Incidence from "../../../components/project_sheet/Incidence/Incidence.svelte";
    import Webcams from "../../../components/project_sheet/Webcams/Webcams.svelte";
    import TechnicalInformationPosts
    from "../../../components/project_sheet/TechnicalInformation/TecnhicalInformationPosts.svelte";
    import {onMount} from "svelte";
    import type {PartialItem} from "@directus/sdk";
    import type {Project} from "../../../types/Project";
    import { addClassName } from "../../../helpers";
    import Description from "../../../components/project_sheet/Description/Description.svelte";
    import LastNews from "../../../components/News/LastNews.svelte";

    export let projectId: number = null;
    let project: PartialItem<Project>;

    onMount(async () => {
        addClassName()
        project = await directusSDK.getItem("proyectos", projectId, {fields: ["*", "imagenes.*"]}) as PartialItem<Project>;
    });
</script>

{#if project}
    <h2>{project.nombre}</h2>
    <div class="flex flex-col gap-4">
        <div class="flex flex-wrap gap-6">
            <TechnicalSheet { project }/>
        </div>
        <Description { project } />
        <Calendar { project }/>
        <Videos id={project.id} field= {'proyecto'} />
        <Images id={project.id} field= {'proyecto'} />
        <Webcams { project }/>
        <TechnicalInformationPosts { project }/>
        <LastNews { project }/>
        <Incidence { project }/>
    </div>
{:else}
    <p>El proyecto no existe o no se tiene acceso a él.</p>
{/if}

