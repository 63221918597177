<script lang="ts">
  import type { PartialItem} from '@directus/sdk';
  import type {Project} from '../types/Project';
  import {url} from '@roxi/routify';
  import {getImageUrl} from '../api';

  export let project: PartialItem<Project> = null;
</script>

{#if project}
    <div class="max-w-xs mx-auto overflow-hidden bg-white rounded-lg shadow-lg">
        <div class="px-4 py-2">
            <h1 class="text-xl font-bold text-gray-800">{project.nombre}</h1>
            {#if project.direccion}
                <p class="mt-1 text-sm text-gray-600">{project.direccion}</p>
            {/if}
        </div>
        <img class="object-cover w-full h-48 mt-2" src="{getImageUrl(project.imagen,{w:400,h:300})}"  alt="{project.nombre}">
        <div class="flex items-center justify-between px-4 py-2 bg-gray-900">
            <h1 class="text-xs font-bold text-white">{new Date(project.fecha_inicio).toLocaleDateString('es-ES',{  year: 'numeric', month: 'long', day: 'numeric'} )}</h1>
            <a href="{$url('/area_privada/mis_proyectos/' + project.id)}" class="px-2 py-1 text-xs font-semibold text-gray-900 uppercase transition-colors duration-200 transform bg-white rounded hover:bg-gray-200 focus:bg-gray-400 focus:outline-none">Entrar</a>
        </div>
    </div>
{/if}
