<script lang="ts">
    import { onMount } from 'svelte';
    import CookiesPolicy from '../../components/CookiesPolicy.svelte';
    import { addClassName } from '../../helpers';

    onMount(() => addClassName())
</script>

<div class="container">
    <CookiesPolicy />
</div>