<style global lang="postcss">
    @import "./App.css";
</style>

<script lang="ts">
    import { Router } from '@roxi/routify';
    import { routes } from '../../.routify/routes';
    import Alert from "../components/Alert.svelte";
    import Notifications from 'svelte-notifications';
</script>

<Notifications item={Alert}>
    <Router routes="{routes}" />
</Notifications>