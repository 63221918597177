<script lang="ts">
    import type { PartialItem } from '@directus/sdk';
    import { directusSDK, getImageUrl } from '../../../api';
    import { onMount } from 'svelte';
    import type { TechnicalInformation } from "../../../types/TechnicalInformation";
    import { url } from '@roxi/routify';
    import { truncateHtml } from '../../../helpers';
    import type { Project } from '../../../types/Project';

    let technical_posts: Array<PartialItem<TechnicalInformation>> = [];
    export let project: PartialItem<Project> = null;

    onMount(async () => {
        technical_posts = (
            await directusSDK.getItems('informacion_tecnica', {
                order: 'desc',
                filter: { proyecto: { _eq: project.id } }
            })
        ).data;
    });
</script>

{#if technical_posts?.length}
    <div>
        <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl ">Información <span class="text-blue-700">técnica</span></h1>
        {#each technical_posts as technical_post, i}
            <div class="py-4">
                <div
                    class="bg-white lg:flex lg:shadow-lg lg:rounded-lg"
                >
                    {#if technical_post.imagen && i % 2 === 0}
                        <div class="lg:w-1/3">
                            <div
                                class="h-64 bg-cover lg:rounded-lg lg:h-full"
                                style="background-image:url('{getImageUrl(
                                    technical_post.imagen,
                                    { w: 400, h: 300 }
                                )}')"
                            ></div>
                        </div>
                    {/if}
                    <div
                        class="_max-w-xl px-6 py-12 {technical_post.imagen
                            ? 'lg:max-w-5xl lg:w-2/3'
                            : ''}"
                    >
                        <a
                            href="{$url(
                                '/area_privada/informacion_tecnica/' + technical_post.id
                            )}"
                        >
                            <h2
                                class="text-2xl font-bold text-gray-800 md:text-3xl"
                            >
                            <a href="{$url('/area_privada/informacion_tecnica/' + technical_post.id)}">{technical_post.titulo}
                            </h2>
                        </a>
                        <p class="mt-4 text-gray-600">
                            {@html truncateHtml(technical_post.contenido, 200)}
                        </p>

                        <div class="mt-8 text-right">
                            <span class="py-2 text-sm font-light text-gray-600"
                                >{new Date(technical_post.fecha).toLocaleDateString(
                                    'es-ES',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }
                                )}</span
                            >
                        </div>
                    </div>
                    {#if technical_post.imagen && i % 2 !== 0}
                        <div class="lg:w-1/3">
                            <div
                                class="h-64 bg-cover lg:rounded-lg lg:h-full"
                                style="background-image:url('{getImageUrl(
                                    technical_post.imagen,
                                    { w: 400, h: 300 }
                                )}')"
                            ></div>
                        </div>
                    {/if}
                </div>
            </div>
        {/each}
    </div>
{/if}