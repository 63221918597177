<script>
    import { linkClass, titleClass, sectionClass, listClass, mainSection, pageTitle, boldListClass } from "../stylesTemplates"
</script>

<section class="{mainSection}">
    <h2 class="{pageTitle}">Política de privacidad</h2>
    <p>El objetivo de esta política es informar a los interesados acerca de los distintos tratamientos realizados por esta organización mediante la página web y que afecten a sus datos personales de conformidad con lo establecido en el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016 y en la Ley Orgánica 3/2018, de 5 de diciembre, de Protección de Datos Personales y garantía de los derechos digitales.</p><br/>

    <section class="{sectionClass}">
        <h3 class="{titleClass}">1. Identificación y datos de contacto del responsable</h3>
        <p>AV DRONE AND INSPECTION SL, domiciliada en C/ DE LAS ENCINAS Nº 10 (28490 BECERRIL DE LA SIERRA) MADRID, con NIF B02789261, teléfono de contacto  y correo electrónico <a href="mailto: info@avdroneandinspection.com" class="{linkClass}">info@avdroneandinspection.com</a>.</p>
    </section>
    <section class="{sectionClass}">
        <h3 class="{titleClass}">2. Finalidades del tratamiento de sus datos personales</h3>
        <ul class="{boldListClass} list-decimal">
            <li>
                <span class="uppercase font-semibold">Usuarios/Navegantes de la página web del responsable</span>
                <p class="font-normal">Trataremos los datos de carácter personal facilitados para:</p>
                <ul class="{listClass} list-disc font-normal">
                    <li>Atender a las solicitudes, quejas e incidencias trasladadas a través de nuestros canales de contacto incorporados en la página web. </li>
                    <li>Entender el comportamiento del navegante dentro de la web con el fin de detectar posibles ataques informáticos a nuestra web.</li>
                    <li>Cumplir con las obligaciones legales que nos resulten directamente aplicables y regulen nuestra actividad. </li>
                    <li>Proteger y ejercer nuestros derechos o responder ante reclamaciones de cualquier índole.</li>
                    <li>En su caso, envío de comunicaciones comerciales relativas a los bienes o servicios que conforman nuestra actividad y/o noticias o boletines relacionados con nuestro sector. Su negativa a facilitarnos la autorización implicará la imposibilidad de enviarle información por parte de la entidad.</li>
                    <li>En su caso, gestionar su participación en concursos y promociones que realice la entidad. Su negativa a facilitarnos la autorización implicará la imposibilidad de participar.</li>
                    <li>En su caso, enviar encuestas de satisfacción y/o calidad. Su negativa a facilitarnos la autorización implicará la imposibilidad de evaluar el servicio prestado.</li>
                    <li>Gestionar su registro en la plataforma web de la entidad y generar un usuario y contraseña. Su negativa a facilitarnos la autorización implicará la imposibilidad de efectuar su registro en la plataforma.</li>
                    <li>Gestionar, administrar, supervisar su actividad en el blog/foro incorporado. Su negativa a facilitarnos la autorización implicará la imposibilidad de participar.</li>
                </ul>
            </li>
            <li>
                <span class="uppercase font-semibold">Clientes</span>
                <p class="font-normal">En suma a las finalidades señaladas en el apartado «usuarios/navegantes de la página web», trataremos sus datos de carácter personal facilitados para:</p>
                <ul class="{listClass} list-disc font-normal">
                    <li>Gestionar la relación comercial. </li>
                    <li>Realizar gestiones administrativas, contables y fiscales. </li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="{sectionClass}">
        <h3 class="{titleClass}">3. Base jurídica del tratamiento</h3>
        <ul class="{boldListClass} list-decimal">
            <li>
                <span class="uppercase font-semibold">Usuarios/Navegantes de la página web del responsable</span>
                <ul class="{listClass} list-disc font-normal">
                    <li>En el consentimiento que nos has prestado para tratar tus datos con las finalidades indicadas. La negativa a facilitar sus datos personales conllevara la imposibilidad de tratar sus datos con las finalidades mencionadas.</li>
                    <li>Para cumplir con las obligaciones legales que se nos aplican. En este caso, el interesado no podrá negarse al tratamiento de los datos personales.</li>
                    <li>En nuestro interés legítimo de proteger nuestra imagen, negocio y trayectoria evitando ataques a nuestra página web. En este caso, el interesado no podrá negarse al tratamiento de los datos personales, aunque podrá ejercer, en su caso, los derechos reconocidos en el apartado «derechos» de la presente política.</li>
                </ul>
            </li>
            <li>
                <span class="uppercase font-semibold">Clientes</span>
                <ul class="{listClass} list-disc font-normal">
                    <li>Ejecución de un contrato en el que sea parte o aplicación de medias precontractuales. La negativa a facilitar sus datos personales conllevara la imposibilidad de tratar sus datos con las finalidades mencionadas.</li>
                    <li>En el consentimiento que nos has prestado para tratar tus datos respecto de las finalidades ajenas al desarrollo o ejecución del contrato existente. La negativa a facilitar sus datos personales conllevara la imposibilidad de tratar sus datos con las finalidades mencionadas.</li>
                    <li>Para cumplir con las obligaciones legales que se nos aplican. En este caso, el interesado no podrá negarse al tratamiento de los datos personales.</li>
                    <li>En nuestro interés legítimo de proteger nuestra imagen, negocio y trayectoria evitando ataques a nuestra página web. En este caso, el interesado no podrá negarse al tratamiento de los datos personales, aunque podrá ejercer, en su caso, los derechos reconocidos en el apartado «derechos» de la presente política.</li>
                </ul>
            </li>
        </ul>
    </section>
    <section class="{sectionClass}">
        <h3 class="{titleClass}">4. Complementos y herramientas del sitio web</h3>
        <ul class="{listClass} list-disc">
            <li>
                <span class="font-semibold">Google Maps</span>
                <p>Utilizamos Google Maps (API) con la finalidad demostrarle mapas interactivos directamente en nuestro sitio web y permitirle utilizar sus funciones. A estos efectos, debe tener en cuenta que, al utilizar el servicio, Google recabará y almacenará información sobre su uso. Le informamos que el uso de Google puede conllevar Transferencias Internacionales de Datos no basadas en una decisión de adecuación de la Comisión Europea ni en el ofrecimiento de garantías adecuadas, por lo que, no cuentan con un nivel idóneo de protección de conformidad con lo dispuesto por el RGPD. A los efectos oportunos, le informamos que este hecho puede conllevar ciertos riesgos para la protección de sus datos de carácter personal. Dichos riesgos derivados pueden incluir que no exista una autoridad supervisora de protección equivalente y/o unos principios de protección de tratamiento de datos y/o unos derechos de protección de las personas interesadas equivalentes. Por todo ello, le recomendamos que, de forma previa, lea detenidamente la política de privacidad de nuestro proveedor haciendo clic <a href="https://policies.google.com/privacy?hl=es" class="{linkClass}">aquí</a>.</p>
            </li>
        </ul>
    </section>
    <section class="{sectionClass}">
        <h3 class="{titleClass}">5. Plazos o criterios de conservación de los datos</h3>
        <p>Los datos personales proporcionados se conservarán durante el tiempo necesario para cumplir con las finalidades para los que fueron recopilados inicialmente.</p><br/>
        <p>Una vez que los datos dejen de ser necesarios para el tratamiento en cuestión, estos se mantendrán debidamente bloqueados para, en su caso, ponerlos a disposición de las Administraciones y Organismos Públicas competentes, Jueces y Tribunales o el Ministerio Fiscal, durante el plazo de prescripción de las acciones que pudieran derivarse de la relación mantenida con el cliente y/o los plazos de conservación previstos legalmente.</p>
    </section>
    <section class="{sectionClass}">
        <h3 class="{titleClass}">6. Decisiones automatizadas y elaboración de perfiles</h3>
        <p>La página web no toma decisiones automatizadas ni elabora perfiles.</p>
    </section>
    <section class="{sectionClass}">
        <h3 class="{titleClass}">7. Destinatarios</h3>
        <p>Durante el periodo de duración del tratamiento de sus datos personales, la organización podrá ceder sus datos a los siguientes destinatarios:</p><br/>
        <ul class="{listClass} list-disc">
            <li>Jueces y Tribunales.</li>
            <li>Fuerzas y Cuerpos de Seguridad del Estado.</li>
            <li>Otras autoridades u organismos públicos competentes, cuando el responsable tenga la obligación  legal de facilitar los datos personales. </li>
            <li>En su caso, a aquellos Encargados de Tratamiento que nos brinden servicios.</li>
        </ul>
    </section>
    <section class="{sectionClass}">
        <h3 class="{titleClass}">8. Transferencias internacionales de datos</h3>
        <p>La organización no realiza Transferencia Internacional de Datos alguna. En caso de que posteriormente fuera necesario realizar transferencias internacionales de datos, se verificará el grado de protección del país de destino y se adoptarán las garantías exigidas por la normativa.</p>
    </section>
    <section class="{sectionClass}">
        <h3 class="{titleClass}">9. Redes sociales</h3>
        <p>Con la finalidad de hacerle partícipe de nuestra actividad, y de que pueda estar al tanto de nuestras novedades, le informamos de que AV DRONE AND INSPECTION SL tiene creado un perfil en las Redes Sociales. </p><br/>
        <p>Todos los usuarios tienen la oportunidad de unirse a nuestras redes sociales o grupos. Sin embargo, debe tener en cuenta que, salvo que le solicitemos sus datos directamente (por ejemplo, mediante acciones de marketing, concursos, promociones, o cualquier otra forma válida), sus datos pertenecerán a la Red Social correspondiente, por lo que le recomendamos que lea detenidamente sus condiciones de uso y políticas de privacidad, así como, se asegure de configurar sus preferencias en cuanto al tratamiento de los datos. </p><br/>
        <p>A continuación, detallamos el enlace a la política de privacidad a las distintas Redes Sociales en las que tenemos presencia, para que pueda acceder en todo momento a las políticas de privacidad y configurar su perfil para garantizar su privacidad:</p><br/>
        <ul class="{boldListClass}">
            <li><a href="https://es-es.facebook.com/privacy/policy/?entry_point=facebook_page_footer" class="{linkClass}">Facebook</a></li>
            <li><a href="https://twitter.com/es/privacy" class="{linkClass}">Twitter</a></li>
            <li><a href="https://privacycenter.instagram.com/" class="{linkClass}">Instagram</a></li>
        </ul>
    </section>
    <section class="{sectionClass}">
        <h3 class="{titleClass}">10. Derechos</h3>
        <p>Los interesados podrán solicitar más información acerca del tratamiento de sus datos personales así como ejercer en cualquier momento y, de forma gratuita, los derechos de acceso, rectificación y supresión, así como solicitar que se limite el tratamiento de sus datos personales, oponerse al mismo, solicitar la portabilidad de estos (siempre que sea técnicamente posible) o retirar el consentimiento prestado y, en su caso, a no ser objeto de una decisión basada únicamente en un tratamiento automatizado, incluido la elaboración de perfiles.</p><br/>
        <p>Para ello podrá emplear los formularios habilitados por la organización, o bien dirigir un escrito a la dirección postal o correo electrónico referenciados en el encabezamiento. A los efectos oportunos, le informamos que se le podrá solicitar su DNI o cualquier otro documento análogo, con la finalidad de acreditar su identidad, siempre que ello no pueda realizarse por otros medios menos intrusivos. </p><br/>
        <p>En caso de que sienta vulnerados sus derechos en lo concerniente a la protección de sus datos personales, especialmente cuando no haya obtenido satisfacción en el ejercicio de sus derechos, puede presentar una reclamación ante la Autoridad de Control en materia de Protección de Datos competente (Agencia Española de Protección de Datos), a través de su sitio web <a href="www.aepd.es" class="{linkClass}">www.aepd.es</a></p><br/>
        <p>En cumplimiento de lo dispuesto en el artículo 21 de la Ley 34/2002 de servicios de la sociedad de la información y comercio electrónico, si usted no desea recibir más información sobre nuestros servicios, puede darse de baja enviando un correo electrónico a la dirección <a href="mailto: info@avdroneandinspection.com" class="{linkClass}">info@avdroneandinspection.com</a>, con asunto “BAJAS” </p>
    </section>
    <section class="{sectionClass}">
        <h3 class="{titleClass}">11. Veracidad de los datos</h3>
        <p>El interesado garantiza que los datos aportados son verdaderos, exactos, completos y se encuentran actualizados; comprometiéndose a informar de cualquier cambio respecto de los datos que aportara, por los canales habilitados al efecto e indicados en el punto uno de la presente política. Será responsable de cualquier daño o perjuicio, tanto directo como indirecto, que pudiera ocasionar como consecuencia del incumplimiento de la presente obligación.</p><br/>
        <p>En el supuesto de que el usuario facilite datos de terceros, declara que cuenta con el consentimiento de los interesados y se compromete a trasladarle la información contenida en esta cláusula, eximiendo a la organización de cualquier responsabilidad derivada por la falta de cumplimiento de la presente obligación.</p>
    </section>  
    <section class="{sectionClass}">
        <h3 class="{titleClass}">12. Modificaciones y actualización</h3>
        <p>La presente política de privacidad puede verse modificada/actualizada en función de las exigencias legales establecidas o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos, o a consecuencia de cambios en nuestro sitio web. Por esta razón, aconsejamos a los usuarios que visiten periódicamente nuestra Política de Privacidad.</p><br/>
        <p>Si tiene dudas acerca de esta política, puede contactar con AV DRONE AND INSPECTION SL a través de los formularios habilitados por la organización, o bien dirigir un escrito a la dirección postal o correo electrónico referenciados en el encabezamiento.</p>
    </section>

    <div class="text-right">Última revisión 15 de Enero de 2024</div>
</section>