<script type="ts">
  import type {ManyItems, PartialItem} from '@directus/sdk';
  import {getNotificationsContext} from 'svelte-notifications';

  import {directusSDK} from "../../../api";
  import type {Project} from '../../../types/Project';
  import {onMount} from "svelte";
  import type {Incidence} from '../../../types/Incidence';
  import Pagination from '../../Pagination/Pagination.svelte';
    import { formatDate } from '../../../helpers';

  export let project: PartialItem<Project> = null;
  let incidencias: Array<PartialItem<Incidence>> = [];
  let total: number = 0;
  let numPerPage: number = 5;

  const {addNotification} = getNotificationsContext();

  async function sendIncidence(e) {
    const data = {};
    const formData = new FormData(e.target);

    for (let field of formData) {
      const [key, value] = field;
      data[key] = value;
    }

    try {
      await directusSDK.addItem("incidencias", data);
      await getIncidences(1, numPerPage);
      addNotification({
        type: "success",
        position: 'top-right',
        text: "Incidencia notificada correctamente.",
        removeAfter: 4000,
      });
    } catch (err) {
      addNotification({
        type: "error",
        position: 'top-right',
        text: 'Parece que ha ocurrido un error. Por favor, inténtelo de nuevo.',
        removeAfter: 4000,
      });
    }

    e.target.reset();
  }

  async function getIncidences(page: number = 1, perPage: number = 5) {
    const incidenceListItems: ManyItems<Incidence> = await directusSDK.getItems("incidencias", {
      limit: perPage,
      sort: ["-fecha"],
      page: page,
      meta: 'total_count',
      filter: {
        proyecto: {_eq: project.id}
      },
    }) as  ManyItems<Incidence>;
    incidencias = incidenceListItems.data;
    total = incidenceListItems.meta.total_count;
  }

  onMount(async () => {
    await getIncidences()
  });
</script>

{#if project}
    <div class="w-full bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4">
        <h2 class="text-3xl font-semibold text-gray-800 lg:text-4xl">
            Incidencias
        </h2>
        <div class="flex">
            <div class="grow">
                <form class="mt-2" on:submit|preventDefault={sendIncidence}>
                    <div class="mb-1">
                        <textarea
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="description"
                                name="description"
                                rows="8"
                                placeholder="Escriba su incidencia..."
                        ></textarea>
                    </div>
                    <div class="hidden">
                        <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="client"
                                name="client"
                                type="hidden"
                                value="{project.cliente}"
                        />
                    </div>
                    <div class="hidden">
                        <input
                                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                id="project"
                                name="proyecto"
                                type="hidden"
                                value="{project.id}"
                        />
                    </div>
                    <div class="flex items-center justify-between">
                        <button
                                class="px-4 mt-2 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                                type="submit"
                        >
                            Notificar incidencia
                        </button>
                    </div>
                </form>
            </div>
</div>
        <div class="flex">
            {#if incidencias.length}
                <div class="bg-white pb-8 mb-4 mt-2 grow ">
                    <div class="flex space-y-3 flex-col break-words">
                        {#each incidencias as incidencia}
                            <div class="shadow-md p-4 border-2">
                                <div class="mb-6">
                                    <span class="font-semibold">Estado: </span><span
                                        class="text-transform: capitalize">{incidencia.status}</span>
                                </div>
                                <div class="mb-2 font-semibold">
                                    Descripción
                                </div>
                                <div class="mb-6">
                                    {incidencia.description}
                                </div>
                                <div class="mb-6">
                                    <span class="font-semibold">Fecha de notificación: </span>{formatDate(incidencia.date_created)}
                                </div>
                            </div>
                        {/each}
                    </div>
                </div>
            {/if}
        </div>
        <Pagination total={total} label={'incidencias por página'} numPerPageOptions={[5, 10, 20]} on:change={(event) => {
            getIncidences(event.detail.page, event.detail.perPage)
            numPerPage = event.detail.perPage
        }}/>
    </div>
{/if}
