<style global lang="postcss">
    @import "./Images.css";
</style>

<script type="ts">
    import type {PartialItem} from "@directus/sdk";
    import type {Image} from "../../types/Image";
    import {directusSDK} from "../../api";
    import ImageCard from "../ImageCard/ImageCard.svelte";
    import {onMount} from "svelte";

    export let id: number;
    export let field: string;
    export let bgWhite: boolean = true;

    let imagenes: Array<PartialItem<Image>> = [];

    onMount(async () => {
        imagenes = (await directusSDK.getItems("imagenes", {
            filter: {
                [field]: {_eq: id}
            },
            fields:['*','imagen.*']
        })).data;
    })
</script>

{#if imagenes.length}
    <div class="{bgWhite ? 'bg-white shadow-md' : ''} col-span-2 px-8 py-4 rounded-lg data-block images-block">
        <div class="flex items-center justify-between">
            <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl ">Imágenes</h1>
        </div>
        <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {#each imagenes as image }
                <ImageCard {image}/>
            {/each}
        </div>
    </div>
{/if}