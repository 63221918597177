<script lang="ts">
    import type {PartialItem} from "@directus/sdk";
    import {directusSDK} from "../../../api";
    import type {Client} from "../../../types/Client";
    import ProjectCard from "../../../components/ProjectCard.svelte";
    import {onMount} from 'svelte';
    import { addClassName } from "../../../helpers";

    let clients: Array<PartialItem<Client>> = [];

    onMount(async () => {
        addClassName()
        clients = (await directusSDK.getItems('clientes', {limit: -1, fields: ['nombre', 'proyectos.*']})).data;
    })

</script>

<h2 class="text-3xl font-semibold text-gray-800 lg:text-4xl">Mis proyectos</h2>
{#each clients as client}
    {#if client.proyectos.length > 0}
        <h3 class="text-blue-600">{ client.nombre }</h3>
        <div class="py-4 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
            {#each client.proyectos as project }
                <ProjectCard {project}/>
            {/each}
        </div>
    {/if}
{/each}

