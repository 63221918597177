<script lang="ts">
    import { onMount } from 'svelte';
    import PasswordResetRequestForm from '../components/PasswordResetRequestForm.svelte';
    import { addClassName } from '../helpers';

    onMount(() => addClassName())
</script>

<div class="container">
    <PasswordResetRequestForm />
</div>