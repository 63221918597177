<script lang="ts">
    import type { PartialItem } from '@directus/sdk';
    import { directusSDK, getImageUrl } from '../../api';
    import type { Project } from '../../types/Project';
    import ProjectCard from '../../components/ProjectCard.svelte';
    import { onMount } from 'svelte';
    import type { News } from '../../types/News';
    import { url } from '@roxi/routify';
    import { addClassName, truncateHtml } from '../../helpers';
    import LastNews from '../../components/News/LastNews.svelte';

    let projects: Array<PartialItem<Project>> = [];
    let news: Array<PartialItem<News>> = [];

    onMount(async () => {
        addClassName();

        projects = (
            await directusSDK.getItems('proyectos', {
                limit: -1,
                filter: {
                    destacado: true,
                },
            })
        ).data;

        news = (
            await directusSDK.getItems('novedades', {
                limit: 4,
                order: 'desc',
            })
        ).data;
    });
</script>

<section class="bg-white  shadow my-3">
    <div class="container px-6 py-8 mx-auto">
        <div class="items-center lg:flex">
            <div>
                <h2 class="text-3xl font-bold text-gray-800 ">
                    <span class="text-blue-600 ">BIENVENIDOS A AVIOT</span>
                </h2>
                <p class="mt-4 text-gray-500  ">
                    AVIOT nace para brindar a nuestros clientes un control
                    completo sobre sus proyectos y servicios contratados con AV
                    DRONE, evitando así largas listas de correos y manejo de
                    información sensible y fundamental para la correcta
                    ejecución de los servicios contratados. <br/><br/>
                    En AVIOT podrás gestionar todos tus proyectos organizados y auditados en
                    base a lo contratado a través de nuestro calendario de
                    actuaciones. Brindamos un servicio de soporte 24x7 donde
                    podrás comunicarnos cualquier incidencia que puedas
                    encontrar. <br/><br/>
                    Somos una empresa de servicios aplicados al
                    sector industrial, agrónomo y audiovisual, mediante la
                    implementación de tecnología disruptiva como puede ser la
                    inteligencia artificial y el uso de drones, ofreciendo entre
                    otros servicios levantamientos topográficos, inspecciones
                    lineales, estructurales y energéticos, análisis del estrés
                    hídrico y nutricional de cultivos. A su vez ponemos a
                    disposición de nuestros clientes un servicio en I+D+I para
                    proyectos personalizado de tecnología e IA. <br/><br/>
                    Además, con nuestros drones y servicios propios, de video y fotografía,
                    desarrollamos productos audiovisuales que integramos en las
                    páginas webs y redes sociales de nuestros clientes,
                    optimizando su comercio electrónico, donde tenemos una
                    amplia experiencia en análisis de posicionamiento SEO e
                    E-Commerce.
                </p>
            </div>
        </div>
    </div>
</section>

{#if projects?.length}
    <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl ">
        Últimos proyectos
    </h1>
    <div
        class="py-4 grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
    >
        {#each projects as project}
            <ProjectCard project="{project}" />
        {/each}
    </div>
{/if}

<LastNews />
