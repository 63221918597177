<script type="ts">
    import type {PartialItem} from "@directus/sdk";
    import type {Project} from "../../../types/Project";
    import {getPdfFileUrl} from "../../../api";
    import Fa from 'svelte-fa';
    import {faFilePdf} from '@fortawesome/free-solid-svg-icons';

    export let project: PartialItem<Project> = null;

    let contract = getPdfFileUrl(project.contrato);
</script>

<div class="flex-grow lg:flex-none bg-white ">
    <div class="flex items-center my-4">
        {#if project.contrato }
            <a target="_blank" href={contract}><span class="inline-block text-blue-500">
                <Fa icon={faFilePdf} size="4x" primaryColor="red"/>
            </span></a>
            <a target="_blank" href={contract} class="m-2 text-2xl font-semibold text-gray-700 capitalize ">Detalle de proyecto</a>
        {:else}
            <p>No hay detalle del proyecto.</p>
        {/if}
    </div>
</div>