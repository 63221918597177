import { Directus } from '@directus/sdk';
export class DirectusSDK {
    constructor() {
        let init = {
            auth: { mode: 'cookie' }
        };
        this.directus = new Directus(DIRECTUS_API_URL, init);
    }
    async addItem(collection, item) {
        try {
            let response = await this.directus.items(collection).createOne(item);
            if (collection == "contactos") {
                this.addNotification({
                    type: 'success',
                    position: 'top-right',
                    text: "Mensaje enviado correctamente.",
                    removeAfter: 4000,
                });
            }
            return response;
        }
        catch (error) {
            this.manageError(error, "No se ha podido enviar su mensaje.");
        }
    }
    checkLogin() {
        if (this.directus.auth.token) {
            this.directus.users.me.read().then(() => {
                this.addNotification({
                    type: 'info',
                    position: 'top-right',
                    text: '¡Ya tiene una sesión iniciada!',
                    removeAfter: 4000,
                });
                window.location.href = "/area_privada";
            }, (error) => {
                localStorage.removeItem('auth_token');
                localStorage.removeItem('auth_expires');
            });
        }
    }
    async resetPassword(token, userPassword) {
        try {
            let response = await this.directus.auth.password.reset(token, userPassword);
            this.addNotification({
                type: 'success',
                position: 'top-right',
                text: 'Clave cambiada correctamente.',
                removeAfter: 4000,
            });
            return response;
        }
        catch (error) {
            this.manageError(error, 'Ya has cambiado de clave o la url de cambio de clave no es correcta.');
        }
    }
    async changePassword(userName, url) {
        let message = "";
        try {
            if (userName == "") {
                message = "Debe especificar un email.";
                throw new Error();
            }
            let response = await fetch(DIRECTUS_API_URL + "/change/password", {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email: userName, url: url })
            });
            this.addNotification({
                type: "success",
                position: 'top-right',
                text: "Enviado email con instrucciones para cambiar la clave.",
                removeAfter: 4000,
            });
            return response;
        }
        catch (error) {
            if (message == "") {
                message = error.message;
            }
            this.manageError(error, message);
        }
    }
    async getItem(collection, id, query) {
        try {
            let response = await this.directus.items(collection).readOne(id, query);
            return response;
        }
        catch (error) {
            this.manageError(error, "No se ha podido obtener un dato del servidor.");
        }
    }
    async getItems(collection, query) {
        try {
            let response = this.directus.items(collection).readMany(query);
            return response;
        }
        catch (error) {
            this.manageError(error, "No se han podido obtener los datos del servidor.");
        }
    }
    getAuthToken() {
        try {
            let token = this.directus.auth.token;
            return token;
        }
        catch (error) {
            this.manageError(error, "El token de sesión ha expirado.");
        }
    }
    async updateUser(user, repitedUser = null) {
        let message = "";
        try {
            if (user.hasOwnProperty("password")) {
                if (user.password !== repitedUser.password) {
                    message = "Las contraseñas introducidas no son iguales";
                    throw new Error();
                }
                console.log("user3");
                message = "La contraseña se ha actualizado correctamente";
            }
            else {
                message = "Usuario modificado correctamente";
            }
            let response = await this.directus.users.me.update(user);
            this.addNotification({
                type: 'success',
                position: 'top-right',
                text: message,
                removeAfter: 4000,
            });
            return response;
        }
        catch (error) {
            if (message == "") {
                if (user.hasOwnProperty("password")) {
                    message = "Ha ocurrido un error al intentar modificar la contraseña.";
                    ;
                }
                else {
                    message = "Ha ocurrido un error al intentar modificar los datos del usuario.";
                }
            }
            this.manageError(error, message);
        }
    }
    async getUser() {
        try {
            let response = await this.directus.users.me.read();
            return response;
        }
        catch (error) {
            this.manageError(error, "No se ha podido recuperar el usuario actual del servidor.");
        }
    }
    async login(email, password) {
        try {
            let response = await this.directus.auth.login({ email: email, password: password });
            window.location.href = '/area_privada';
            return response;
        }
        catch (error) {
            this.addNotification({
                type: 'error',
                position: 'top-right',
                text: "Credenciales no válidas.",
                removeAfter: 4000,
            });
        }
    }
    logout() {
        this.directus.auth.logout().then(() => {
            window.location.href = "/";
        }, (error) => {
            this.addNotification({
                type: 'error',
                position: 'top-right',
                text: "No se ha podido cerrar sesión:",
                removeAfter: 4000,
            });
        });
    }
    setAddNotificationContext(addNotificationContext) {
        this.addNotificationContext = addNotificationContext;
    }
    addNotification(notification) {
        this.addNotificationContext(notification);
    }
    manageError(error, message = "") {
        if (error.message == "Token expired.") {
            this.directus.auth.refresh()
                .then((response) => { return true; })
                .catch((tokenError) => { error = tokenError; });
        }
        this.addNotification({
            type: 'error',
            position: 'top-right',
            text: message,
            removeAfter: 4000,
        });
        if (error["extensions"] !== undefined) {
            this.addNotification({
                type: 'error',
                position: 'top-right',
                text: error.message,
                removeAfter: 4000,
            });
            if (error.extensions.code == "FORBIDDEN") {
                this.directus.users.me.read().catch(() => {
                    this.logout();
                });
            }
        }
    }
}
