<script lang="ts">
    import type { PartialItem } from '@directus/sdk';
    import { directusSDK, getImageUrl } from '../../api';
    import { onMount } from 'svelte';
    import type { News } from '../../types/News';
    import { url } from '@roxi/routify';
    import { truncateHtml } from '../../helpers';
    import type { Project } from '../../types/Project';

    let news: Array<PartialItem<News>> = [];
    export let project: PartialItem<Project> = null;

    onMount(async () => {
        const filter = project ? { proyecto: { _eq: project.id } } : {};
        news = (
            await directusSDK.getItems('novedades', {
                limit: 4,
                order: 'desc',
                filter,
            })
        ).data;
    });
</script>

{#if news?.length}
    <div>
        <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl ">
            Últimas novedades
        </h1>
        {#each news as singleNew, i}
            <div class="py-4">
                <div
                    class="bg-white lg:flex lg:shadow-lg lg:rounded-lg"
                >
                    {#if singleNew.imagen && i % 2 === 0}
                        <div class="lg:w-1/3">
                            <div
                                class="h-64 bg-cover lg:rounded-lg lg:h-full"
                                style="background-image:url('{getImageUrl(
                                    singleNew.imagen,
                                    { w: 400, h: 300 }
                                )}')"
                            ></div>
                        </div>
                    {/if}
                    <div
                        class="_max-w-xl px-6 py-12 {singleNew.imagen
                            ? 'lg:max-w-5xl lg:w-2/3'
                            : ''}"
                    >
                        <a
                            href="{$url(
                                '/area_privada/novedades/' + singleNew.id
                            )}"
                        >
                            <h2
                                class="text-2xl font-bold text-gray-800 md:text-3xl"
                            >
                                {singleNew.titulo}
                            </h2>
                        </a>
                        <p class="mt-4 text-gray-600">
                            {@html truncateHtml(singleNew.contenido, 200)}
                        </p>

                        <div class="mt-8 text-right">
                            <span class="py-2 text-sm font-light text-gray-600"
                                >{new Date(singleNew.fecha).toLocaleDateString(
                                    'es-ES',
                                    {
                                        year: 'numeric',
                                        month: 'long',
                                        day: 'numeric',
                                    }
                                )}</span
                            >
                        </div>
                    </div>
                    {#if singleNew.imagen && i % 2 !== 0}
                        <div class="lg:w-1/3">
                            <div
                                class="h-64 bg-cover lg:rounded-lg lg:h-full"
                                style="background-image:url('{getImageUrl(
                                    singleNew.imagen,
                                    { w: 400, h: 300 }
                                )}')"
                            ></div>
                        </div>
                    {/if}
                </div>
            </div>
        {/each}
        <div class="flex text-center justify-center">
            <a
                class="show-all-news text-gray-900 visited:text-blue-00 text-lg"
                href="{$url('/area_privada/novedades/')}">Ver todas</a
            >
        </div>
    </div>
{/if}
