<script type="ts">
    import type { PartialItem } from '@directus/sdk';
    import { directusSDK } from '../../api';
    import type { News } from '../../types/News';
    import { onMount } from 'svelte';
    import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
    import Fa from 'svelte-fa';
    import type { NewsFile } from '../../types/NewsFile';
    import { getPdfFileUrl } from '../../api';
    import type { TechnicalInformation } from '../../types/TechnicalInformation';

    export let news: PartialItem<News> = null;
    export let technical_info: PartialItem<TechnicalInformation> = null;
    export let bgWhite: boolean = true;
    let files: Array<PartialItem<NewsFile>> = [];

    onMount(async () => {
        const fields = ['*', 'directus_files_id.*']
        
        if (news) {
            files = (
                await directusSDK.getItems('novedades_files', {
                    filter: {
                        novedades_id: { _eq: news.id },
                    },
                    fields,
                })
            ).data;
        } else if (technical_info) {
            files = (
                await directusSDK.getItems('informacion_tecnica_files', {
                    filter: {
                        informacion_tecnica_id: { _eq: technical_info.id },
                    },
                    fields,
                })
            ).data;
        }
    });
</script>

{#if files.length}
    <div class="{bgWhite ? 'bg-white shadow-md' : ''} col-span-2 px-8 py-4 rounded-lg">
        <div class="flex items-center justify-between ">
            <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl ">
                Archivos
            </h1>
        </div>
        <div
            class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
        >
            {#each files as file}
                <div class="flex items-center my-4 mr-4 self-center">
                    <a
                        target="_blank"
                        href="{getPdfFileUrl(file.directus_files_id.id)}"
                        ><span class="inline-block text-blue-500">
                            <Fa
                                icon="{faFilePdf}"
                                size="4x"
                                primaryColor="red"
                            />
                        </span></a
                    >
                    <a
                        target="_blank"
                        href="{getPdfFileUrl(file.directus_files_id.id)}"
                        class="m-2 text-2xl font-semibold text-gray-700 capitalize "
                        download>
                        {#if file.directus_files_id.title}
                            {file.directus_files_id.title}
                        {:else}
                            Archivo
                        {/if}
                        </a
                    >
                </div>
            {/each}
        </div>
    </div>
{/if}
