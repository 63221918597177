<h2>Novedades</h2>

<script lang="ts">
    import {directusSDK } from "../../../api";
    import type { News } from "../../../types/News";
    import type { ManyItems, PartialItem } from "@directus/sdk";
    import { getImageUrl } from "../../../api";
    import Pagination from '../../../components/Pagination/Pagination.svelte';
    import { url } from '@roxi/routify';
    import { addClassName, truncateHtml } from '../../../helpers'
    import { onMount } from "svelte";

    let newsList: Array<PartialItem<News>> = [];
    let total: number = 0;
    async function getNews(page: number = 1, perPage: number = 10) {
        let newsListItems: ManyItems<News> = await directusSDK.getItems("novedades", {limit: perPage, sort: ["-fecha"], page: page, meta: 'total_count' });
        newsList = newsListItems.data;
        total = newsListItems.meta.total_count;
    }

    onMount(() => addClassName())
</script>

{#each newsList as news, i}
    <section class="my-3">
        <div class="bg-white lg:mx-8 lg:flex lg:shadow-lg lg:rounded-lg">
            {#if news.imagen && i%2===0}
                <div class="lg:w-1/3">
                    <div class="h-64 bg-cover lg:rounded-lg lg:h-full" style="background-image:url('{getImageUrl(news.imagen,{w:400,h:300})}')"></div>
                </div>
            {/if}
            <div class="_max-w-xl px-6 py-12 {news.imagen?'lg:max-w-5xl lg:w-2/3':''}">
                <a href="{$url('/area_privada/novedades/' + news.id)}">
                    <h2 class="text-2xl font-bold text-gray-800 md:text-3xl">{ news.titulo }</h2>
                </a>
                <p class="mt-4 text-gray-600">{@html truncateHtml (news.contenido, 200)}</p>

                <div class="mt-8 text-right">
                    <span class="py-2 text-sm font-light text-gray-600">{new Date(news.fecha).toLocaleDateString('es-ES',{  year: 'numeric', month: 'long', day: 'numeric'} )}</span>
                </div>
            </div>
            {#if news.imagen && i%2!==0}
                <div class="lg:w-1/3">
                    <div class="h-64 bg-cover lg:rounded-lg lg:h-full" style="background-image:url('{getImageUrl(news.imagen,{w:400,h:300})}')"></div>
                </div>
            {/if}
        </div>
    </section>
{/each}

<Pagination total={total} label={'novedades por página'} numPerPageOptions={[10, 25, 50, 100]} on:change={(event) => getNews(event.detail.page, event.detail.perPage)} />
