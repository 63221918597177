<style global lang="postcss">
    @import './Calendar.css';
</style>

<script type="ts">
    import type { PartialItem } from '@directus/sdk';
    import type { Project } from '../../../types/Project';
    import type { News } from '../../../types/News';
    import { directusSDK } from '../../../api';
    import { onMount } from 'svelte';
    import type { Configuration } from '../../../types/Configuration';
    import Calendar from '@event-calendar/core';
    import DayGrid from '@event-calendar/day-grid';
    import type { CalendarEvent } from '../../../types/CalendarEvent';
    import NewsModal from '../../News/NewsModal.svelte';

    export let project: PartialItem<Project> = null;
    let isOpen = false;
    let newsId = null

    const openModal = (eventId: number) => {
        isOpen = true
        newsId = eventId
    }

    let newsList: Array<PartialItem<News>> = [];
    const plugins = [DayGrid];
    let options: Record<string, any> = {
        view: 'dayGridMonth',
        locale: 'es-ES',
        firstDay: 2, // set monday as first column
        buttonText: {
            today: 'Hoy',
        },
        headerToolbar: {
            start: 'title',
            center: '',
            end: 'today prev next',
        },
        events: [],
        eventClick: (info) => openModal(info.event.id),
    };

    onMount(async () => {
        const currentDate = new Date();
        const firstDayOfMonth = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth(),
            1
        );

        let calendarCategory: PartialItem<Configuration> = (
            await directusSDK.getItems('configuracion', {
                fields: ['categoria_calendario'],
            })
        ).data as PartialItem<Configuration>;

        let calendarCategoryId: number = calendarCategory.categoria_calendario;

        let newsFilter = {
            filter: {
                proyecto: project.id,
                categoria: calendarCategoryId,
                fecha: {
                    _gte: firstDayOfMonth.toISOString(),
                },
            },
        };

        newsList = (await directusSDK.getItems('novedades', newsFilter)).data;

        const events: (CalendarEvent | undefined)[] = newsList.map((ev) => {
            if (ev.id && ev.fecha && ev.titulo) {
                const d = new Date(ev.fecha);
                // calendar set events one day before start date
                d.setDate(d.getDate() + 1)

                return {
                    id: ev.id.toString(),
                    title: ev.titulo,
                    start: d,
                    end: d,
                    allDay: true,
                    display: 'auto',
                };
            }
        });

        options.events = events;
    });
</script>

<div class="col-span-2 px-8 py-4 bg-white rounded-lg shadow-md ">
    <h1 class="text-left text-3xl font-semibold text-gray-800 lg:text-4xl ">Calendario</h1>
    <Calendar plugins="{plugins}" options="{options}" />
    <NewsModal bind:isOpen bind:newsId />
</div>
