<script lang="ts">
    import {directusSDK} from '../../api';
    import {onMount} from 'svelte';
    import {goto} from "@roxi/routify";

    let userName: string = "";
    let userPassword: string = "";

    onMount(async() => {
        directusSDK.checkLogin();
    });

    async function handleOnSubmit() {
        await directusSDK.login(userName, userPassword);
    }

    async function handlePasswordRequest() {
        $goto('/recuperar_clave');
    }
</script>

<div class="bg-white ">
    <div class="flex justify-center h-screen">
        <div class="hidden bg-cover lg:block lg:w-2/3" style="background: no-repeat center url(/images/logo.svg)">
            <!--<div class="flex items-center h-full px-20 bg-gray-900 bg-opacity-40">
                <div>
                    <h2 class="text-4xl font-bold text-white">AVDRONE</h2>

                    <p class="max-w-xl mt-3 text-gray-300">Lorem ipsum dolor sit, amet consectetur adipisicing elit. In autem ipsa, nulla laboriosam dolores, repellendus perferendis libero suscipit nam temporibus molestiae</p>
                </div>
            </div>-->
        </div>

        <div class="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6">
            <div class="flex-1">
                <div class="text-center">
                    <h2 class="text-4xl font-bold text-center text-gray-700 ">AVDRONE</h2>

                    <p class="mt-3 text-gray-500 ">Introduce tu datos de acceso</p>
                </div>

                <div class="mt-8">
                    <form on:submit|preventDefault="{handleOnSubmit}" action="/">
                        <div>
                            <label for="username" class="block mb-2 text-sm text-gray-600 ">Email</label>
                            <input required type="email" bind:value="{userName}" name="username" id="username" placeholder="Tu email" class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md     focus:border-blue-400 :border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>

                        <div class="mt-6">
                            <div class="flex justify-between mb-2">
                                <label for="password" class="text-sm text-gray-600 ">Clave de acceso</label>
                                <a href={"#"} on:click|preventDefault="{handlePasswordRequest}" class="text-sm text-gray-400 focus:text-blue-500 hover:text-blue-500 hover:underline">¿Has olvidado tu clave?</a>
                            </div>

                            <input required type="password" bind:value="{userPassword}" name="password" id="password" placeholder="Tu clave de acceso" class="block w-full px-4 py-2 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-md     focus:border-blue-400 :border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40" />
                        </div>

                        <div class="mt-6">
                            <button
                                    class="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-blue-500 rounded-md hover:bg-blue-400 focus:outline-none focus:bg-blue-400 focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                                Iniciar sesión
                            </button>
                        </div>

                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
