<script lang="ts">
    import {directusSDK} from "../../api";
    import type { PartialItem } from "@directus/sdk";
    import type { User } from "../../types/User";
    import { onMount } from "svelte";
    import { addClassName } from "../../helpers";

    export let user: PartialItem<User> = {};

    let newPassword: string = "";
    let repitedPassword: string = "";

    onMount(async() => {
        addClassName()
        user = await directusSDK.getUser();
    });

    async function modifyUser()
    {
        user = await directusSDK.updateUser({ first_name: user.first_name, last_name: user.last_name });
    }

    async function resetPassword()
    {
        user = await directusSDK.updateUser({ password: newPassword }, { password: repitedPassword });
    }
</script>


<h2>Mis datos</h2>
<div class="py-4 grid grid-cols-1 gap-6 mt-4 md:grid-cols-2">
<section class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg font-semibold text-gray-700">Mis datos de usuario</h2>
    
    <form on:submit|preventDefault="{modifyUser}" method="post" action="">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-6 mt-4">
            <div class="w-full">
                <label class="text-gray-700" for="email">Email</label>
                <input required id="email" type="email" readonly class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" value={user.email}>
            </div>
            <div class="w-full"></div>
            <div class="w-full">
                <label class="text-gray-700" for="name">Nombre</label>
                <input required name="name" id="name" type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-mdfocus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" bind:value={user.first_name}>
            </div>

            <div class="w-full">
                <label class="text-gray-700" for="lastname">Apellidos</label>
                <input required name="lastname" id="lastname" type="text" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" bind:value={user.last_name}>
            </div>
        </div>

        <div class="flex justify-end mt-6">
            <button type="submit" class="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Guardar</button>
        </div>
    </form>
</section>
<section class="p-6 bg-white rounded-md shadow-md">
    <h2 class="text-lg font-semibold text-gray-700">Modificar mi clave de acceso</h2>
    <form on:submit|preventDefault="{resetPassword}" method="post" action="">
        <div class="grid grid-cols-1 gap-6 mt-4">
            <div class="w-full">
                <label class="text-gray-700" for="new-password">Nueva contraseña</label>
                <input required name="new-password" id="new-password" type="password" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" bind:value={newPassword}>
            </div>

            <div class="w-full">
                <label class="text-gray-700 " for="repite-password">Repite contraseña</label>
                <input required name="repite-password" id="repite-password" type="password" class="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-200 rounded-md  focus:border-blue-400 focus:ring-blue-300 focus:ring-opacity-40 focus:outline-none focus:ring" bind:value={repitedPassword}>
            </div>
        </div>

        <div class="flex justify-end mt-6">
            <button type="submit" class="px-6 py-2 leading-5 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">Guardar</button>
        </div>
    </form>
</section>
</div>

<style global lang="postcss">
    input:read-only {
        @apply bg-slate-100;
    }

    input:read-only:focus-visible {
        @apply outline-none;
    }
</style>