<script lang="ts">
    import type {PartialItem} from "@directus/sdk";
    import type {User} from "../types/User";
    import {directusSDK} from '../api';
    import {onMount} from "svelte";
    import {url, isActive} from '@roxi/routify';

    export let user: PartialItem<User> = null;
    onMount(() => {
        const btn = document.querySelector("button.mobile-menu-button");
        const menu = document.querySelector(".mobile-menu");
        // Add Event Listeners
        btn.addEventListener("click", () => {
            menu.classList.toggle("hidden");
        });
    });

    const parentPath = '/area_privada';
    const links = [
        [parentPath + '/mis_proyectos', 'Mis proyectos'],
        [parentPath + '/novedades', 'Novedades'],
        [parentPath + '/mis_datos', 'Mis datos'],
        [parentPath + '/contacto', 'Contacto'],
    ];
</script>

<nav class="bg-white shadow">
    <div class="container px-6 py-4 mx-auto">
        <div class="md:flex md:items-center md:justify-between">
            <div class="w-full flex items-center justify-between">
                <div class="text-xl font-semibold text-gray-700">
                    <a class="text-2xl font-bold text-gray-800 transition-colors duration-200 transform lg:text-3xl hover:text-gray-700"
                       href="{$url('/area_privada')}"><img src="/images/logo.svg" alt="AVDRONE" title="AVDRONE" style="width:200px;height:auto;"/></a>
                </div>

                <div class="hidden lg:block flex-1 md:items-center md:justify-between">
                    <div class="flex flex-col -mx-4 md:flex-row md:items-center md:mx-8">
                        {#each links as [path, name]}
                            <a href={$url(path)}
                               class="{$isActive(path)?'border-b-2 border-blue-500':''} px-2 py-1 mx-2 mt-2 text-sm font-medium text-gray-700 transition-colors duration-200 transform rounded-md md:mt-0 hover:bg-gray-300">
                                {name}
                            </a>
                        {/each}
                    </div>
                </div>

                <div class="flex items-center">
                    {#if user}
                        <h3 class="mx-2 text-xs lg:text-sm font-medium text-gray-700">{user.first_name + ' ' + user.last_name}</h3>
                        <div class="relative inline-block ">
                            <a on:click|preventDefault="{() => { directusSDK.logout() }}"
                               href={"#"}
                               class="flex items-center p-3 text-sm text-gray-600 capitalize transition-colors duration-200 relative">
                                <svg class="w-5 h-5 mx-1" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M19 21H10C8.89543 21 8 20.1046 8 19V15H10V19H19V5H10V9H8V5C8 3.89543 8.89543 3 10 3H19C20.1046 3 21 3.89543 21 5V19C21 20.1046 20.1046 21 19 21ZM12 16V13H3V11H12V8L17 12L12 16Z"
                                          fill="currentColor"></path>
                                </svg>
                                <span class="mx-1 text-xs lg:text-sm hidden sm:inline">Cerrar sesión</span>
                            </a>
                        </div>
                    {/if}
                </div>

                <!-- Mobile menu button -->
                <div class="flex md:hidden">
                    <button type="button"
                            class="mobile-menu-button text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600"
                            aria-label="toggle menu">
                        <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                            <path fill-rule="evenodd"
                                  d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
        <div class="mobile-menu hidden md:block lg:hidden flex-1 md:items-center md:justify-between">
            <div class="flex flex-col -mx-4 md:flex-row md:items-center ">
                {#each links as [path, name]}
                    <a href="{$url(path)}"
                       class="{$isActive(path)?'border-b-2 border-blue-500':''} px-2 py-1 mx-2 mt-2 text-sm font-medium text-gray-700 transition-colors duration-200 relative rounded-md md:mt-0 hover:bg-gray-300">
                        {name}
                    </a>
                {/each}
            </div>
        </div>
    </div>
</nav>

