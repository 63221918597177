<style lang="postcss">
    .not-found-card {
        @apply bg-gray-100;
        @apply rounded-lg;
        @apply shadow-xl;
        @apply border;
        @apply p-8;
    }
</style>
<main>
    <div class="container">
        <div class="grid place-items-center h-screen justify-center">
            <div class="not-found-card">
                <h3>404 - Página no encontrada</h3>
                <p class="text-center">
                    Esta página no existe o no tiene permiso para verla.
                </p>
            </div>
        </div>
    </div>
</main>
