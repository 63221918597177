<script lang="ts">
    import { onMount } from 'svelte';
    import PasswordChangeForm from '../components/PasswordChangeForm.svelte';
    import { addClassName } from '../helpers';

    onMount(() => addClassName())
</script>

<div class="container">
    <PasswordChangeForm />
</div>