<style lang="postcss">
    .login-card {
        @apply bg-gray-100;
        @apply rounded-lg;
        @apply shadow-xl;
        @apply border;
        @apply p-8;
    }
</style>

<script lang="ts">
  import {directusSDK} from '../api';
  import {onMount} from 'svelte';
  import Loading from './Loading.svelte'
  import {goto, params} from '@roxi/routify';

  let userPassword: string = '';

  let loading = false;
  let done = false;

    onMount(async() => {
        directusSDK.checkLogin();
    });    

    async function handleOnSubmit() {
        loading = true;
        await directusSDK.resetPassword(<string>$params.token, userPassword);
        done = true;
        loading = false;
    }

</script>

<div class="grid place-items-center h-screen justify-center">
    <div class="login-card">
        <a href="/"><h1>AVDRONE</h1></a>
        <h3 class="text-center">Cambiar mi clave de acceso</h3>

        <form
                class="grid grid-rows gap-4"
                on:submit|preventDefault="{handleOnSubmit}"
                action="/"
        >

            <div class="form-group">
                <label for="password" class="form-control whitespace-nowrap text-right">Nueva clave:</label>
                <input
                        id="password"
                        class="form-control"
                        type="password"
                        bind:value="{userPassword}"
                />
            </div>

            <button class="btn btn-primary flex flex-row {done ? 'hidden':''}" disabled={loading} type="submit">
                <Loading classNames="{loading ? '':'hidden'}"/>
                Cambiar mi clave de acceso
            </button>

            <button class="btn btn-primary {done ? '':'hidden'}" on:click|preventDefault="{()=>$goto('/')}">
                Hacer login
            </button>
            <p class="text-center">
                <a on:click|preventDefault="{()=>$goto('/recuperar_clave')}" href="/">
                    Volver a reestablecer mi clave de acceso
                </a>
            </p>
        </form>
    </div>
</div>
