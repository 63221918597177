<script type="ts">
    import type {PartialItem} from "@directus/sdk";
    import {directusSDK, getImageUrl} from "../../../api";
    import type {Project} from "../../../types/Project";
    import type { Webcam } from "../../../types/Webcam";
    import {onMount} from "svelte";
    import Fa from 'svelte-fa';
    import {faExternalLinkAlt} from '@fortawesome/free-solid-svg-icons';
    import WebcamModal from "./WebcamModal.svelte";

    export let project: PartialItem<Project>;

    let isOpen = false;
    let titulo = '';
    let url = '';

    const openModal = (tituloCam: string, urlCam: string) => {
        titulo = tituloCam;
        url = urlCam;
        isOpen = true;
    }

    let webcams: Array<PartialItem<Webcam>> = [];

    onMount(async () => {
        webcams = (await directusSDK.getItems("webcams", {filter: { proyecto: {_eq: project.id}}})).data as PartialItem<Webcam>[];
    })
</script>
{#if webcams.length}
    <div class="col-span-2 px-8 py-4 bg-white rounded-lg shadow-md ">
        <div class="flex ">
            <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl ">Webcams</h1>
        </div>
        <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {#each webcams as webcam }
            <div class="flex flex-col cursor-pointer" on:click={() => openModal(webcam.titulo, webcam.url)}>
                <div class="w-100 h-100 inline-block grow ">
                    <img class="webcam-thumbnail object-contain w-full h-full min-w-0" src="{getImageUrl(webcam.thumbnail,{w:400,h:300})}" alt="{webcam.titulo + '-image'}">
                </div>
                <div class="text-center">
                    {webcam.titulo||''} <Fa fw icon={faExternalLinkAlt} />
                </div>
            </div>
            {/each}
        </div>
        <WebcamModal bind:isOpen title={titulo} url={url} />
    </div>
{/if}