<script lang="ts">
    import type { News } from "../../types/News";
    import {directusSDK } from "../../api";
    import type { PartialItem } from "@directus/sdk";
    import Loading from '../Loading.svelte'
    import NewsDetail from "./NewsDetail.svelte";

    export let isOpen = false;
    export let newsId: number | null = null
    let news: PartialItem<News>;

    async function fetchNews() {
        news = await directusSDK.getItem("novedades", newsId, { fields: ['*', 'categoria.nombre', 'proyecto.nombre'] });        
    }

    const closeModal = () => {
        isOpen = false;
        news = null
        newsId = null
    }

    $: {
        if (!news && newsId !== null) {
            fetchNews()
        }
    }
</script>

{#if isOpen}        
    <div class="z-10 modal-wrapper fixed inset-0 bg-black bg-opacity-50 transition-opacity m-0" style="{'pointer-events: auto'}">
        <div class="fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white rounded-lg shadow-lg p-8 w-9/12 h-5/6 overflow-y-auto">
            <div class="flex justify-end">
                <button class="close-modal-button right-2 text-gray-900 hover:text-gray-900 focus:outline-none bg-zinc-300" on:click={() => closeModal()}>
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="white">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>
            </div>
            {#if news}
                <NewsDetail bgWhite={false} news={news} />
            {:else}
                <div class='flex text-center justify-center content-center items-center h-full'>
                    <Loading />
                </div>
            {/if}
        </div>
    </div>
{/if}
