<script lang="ts">
    import type {PartialItem} from '@directus/sdk';
    import type {Video} from '../../types/Video';

    export let video: PartialItem<Video>;

    export let showPopUp = (videoFromVimeo, video: PartialItem<Video>) => {};

    async function getVideoFromVimeo(url: string) {
        try{
            const apiEndpoint: string = "https://vimeo.com/api/oembed.json?url=" + encodeURIComponent(url) + "&widht=800&height=600";
            
            const response = await fetch(apiEndpoint, {
                method: 'GET',
                mode: 'cors',
                cache: 'no-cache',
            });

            return await response.json();
        } catch(error) {
            console.log(error);
        }        
    }
</script>

{#if video}  
    {#await getVideoFromVimeo(video.video)}
        <p>Esperando datos...</p>
    {:then videoFromVimeo}
        <div class="max-w-sm rounded overflow-hidden bg-slate-100 shadow-lg">
            <img class="cursor-pointer w-full p2 border-slate-300" src={videoFromVimeo.thumbnail_url_with_play_button} alt={videoFromVimeo.title} on:click={ () => { showPopUp(videoFromVimeo, video) } }/>

            <div class="px-6 py-4 text-center">
                <div class="font-bold text-xl mb-2">
                    {videoFromVimeo.title||''}
                </div>
                <div class="mb-2 text-gray-700">
                    {video.description ||''}
                </div>
                <p class="text-gray-700 text-base">
                    {new Date(video.fecha).toLocaleDateString('es-ES',{  year: 'numeric', month: 'long', day: 'numeric'} )}
                </p>
            </div>
        </div>
    {/await}
{/if}
