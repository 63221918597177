
<script type="ts">
    import type {PartialItem} from "@directus/sdk";
    import type {Project} from "../../../types/Project";
    import Fa from "svelte-fa";
    import {faMapLocation} from "@fortawesome/free-solid-svg-icons";
    import Contract from "../Contract/Contract.svelte";
    import { onMount } from "svelte";
    import { directusSDK } from "../../../api";
    import type { Client } from "../../../types/Client";

    export let project: PartialItem<Project> = null;
    let client: PartialItem<Client> = null;
    let option = ''

    let coordinates
    if(!coordinates && project?.lat && project?.lng){
        coordinates = [project?.lng,project?.lat];
    }

    const options = {
        seguimiento: 'Seguimiento obra',
        inspeccion: 'Inspección técnica',
        asesoramiento: 'Asesoramiento técnico de cultivos',
        produccion: 'Producción audiovisual'
    }

    onMount(async () => {
        client = (await directusSDK.getItem("clientes", project.cliente, {fields: ["*"]})) as PartialItem<Client>

        if(project.tipo){
            option = options[project.tipo]
        }
    })
</script>
<div class="flex-grow px-8 py-4 bg-white rounded-lg shadow-md ">
    <div class="flex items-center justify-between">
        <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl ">Información del  <span class="text-blue-700">proyecto</span>
        </h1>
    </div>
    <ul>
        {#if client?.nombre}
            <li><span class="project-detail-client-name field-label">Nombre del cliente: </span>{ client.nombre }</li>
        {/if}
        {#if project.contacto}
            <li><span class="project-detail-location field-label">Ubicación: </span>{ project.direccion }
                {#if coordinates}
                    <a target="_blank" href="http://www.google.com/maps/place/{coordinates[1]},{coordinates[0]}">
                        <Fa icon={faMapLocation} class="inline pl-2"/>
                    </a>
                {/if}
            </li>
        {/if}
        {#if project.contacto}
            <li><span class="project-detail-contact field-label">Persona de contacto: </span>{ project.contacto }</li>
        {/if}
        {#if project.email}
            <li><a href="mailto:{ project.email }" class="text-gray-700"><span class="project-detail-email field-label">Email: </span>{ project.email }</a></li>
        {/if}
        {#if project.telefono}
            <li><a href="tel:{ project.telefono }" class="text-gray-700"><span class="project-detail-tel field-label">Teléfono </span>{ project.telefono }</a></li>
        {/if}
        {#if project.fecha_inicio}
            <li><span class="project-detail-start-date field-label">Fecha de inicio: </span>{new Date(project.fecha_inicio).toLocaleDateString('es-ES',{  year: 'numeric', month: 'long', day: 'numeric'} )}</li>
        {/if}
        {#if project.fecha_fin}
            <li><span class="project-detail-end-date field-label">Fecha de fin: </span>{new Date(project.fecha_fin).toLocaleDateString('es-ES',{  year: 'numeric', month: 'long', day: 'numeric'} )}</li>
        {/if}
        {#if project.tipo}
            <li><span class="project-detail-type field-label">Tipo de proyecto: </span>{ option }</li>
        {/if}
    </ul>

    <Contract { project }/>
</div>