
<script type="ts">
    import type {PartialItem} from "@directus/sdk";
    import type {Project} from "../../../types/Project";

    export let project: PartialItem<Project> = null;
</script>
<div class="flex-grow px-8 py-4 bg-white rounded-lg shadow-md ">
    <div class="flex items-center justify-between">
        <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl ">Descripción general del <span class="text-blue-700">proyecto</span></h1>
    </div>
    {#if project.descripcion}
        <p class="project-detail-description text-gray-900">{project.descripcion}</p>
    {/if}
</div>