<style global lang="postcss">
    @import "../assets/styles.css";
</style>

<script lang="ts">
    import { getNotificationsContext } from 'svelte-notifications';
    import { directusSDK } from "../api";
    import { onMount } from 'svelte';
    import { addClassName } from '../helpers';
        
    const { addNotification } = getNotificationsContext();
    directusSDK.setAddNotificationContext(addNotification);

    onMount(() => addClassName())
</script>

<main>
    <slot/>
</main>
