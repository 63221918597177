<script>
    import { linkClass, titleClass, sectionClass, listClass, mainSection, pageTitle, boldListClass } from "../stylesTemplates"
</script>

<section class="{mainSection}">   
    <h2 class="{pageTitle}">Política de cookies</h2>
    <p>Esta página web únicamente utiliza cookies técnicas con la finalidad de optimizar el sitio web y asegurar su correcto funcionamiento. No recaba ni cede datos de carácter personal de los usuarios. Para obtener información adicional sobre el uso de las cookies, acceda a nuestra Política de Cookies.</p>
    <div class="mt-10">
        <div>
            <h3 class="text-xl">Uso de cookies. ¿Qué son las cookies?</h3>
            <p>Las cookies son ficheros que se descargan en su Ordenador, Smartphone o Tablet al acceder a determinadas páginas web, que almacenan y recuperan información cuando navegas.  La utilización de cookies, ofrece numerosas ventajas en la prestación de servicios de la Sociedad de la Información, puesto que, entre otras:</p><br/>
            <ul class="{listClass} list-disc">
                <li>Facilita la navegación del usuario en el Sitio Web.</li>
                <li>Facilita al usuario el acceso a los diferentes servicios que ofrece el Sitio Web.</li>
                <li>Evita al usuario volver a configurar las características generales predefinidas cada vez que accede al Sitio Web.</li>
                <li>Favorece la mejora del funcionamiento y de los servicios prestados a través del Sitio Web, tras el correspondiente análisis de la información obtenida a través de las cookies instaladas</li>
                <li>Permiten a un Sitio Web, entre otras cosas, almacenar y recuperar información sobre los hábitos de navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma en que utilice su equipo, pueden utilizarse para reconocer al usuario. </li>
            </ul><br/>

            <p>De conformidad con lo dispuesto en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y de Comercio Electrónico, y el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo de 27 de abril de 2016, informamos que esta <span class="font-semibold">web no utiliza cookies para recoger información de los usuarios. Únicamente se utilizan cookies con finalidad técnica y de personalización, con la finalidad de permitir su navegación y para posibilitar que determine sus preferencias.</span></p><br/>
            <p>Con el fin de proporcionarle la máxima información posible, procedemos, en primer lugar, a realizar una clasificación de las cookies, en función de una serie de categorías:</p><br/>

            <p class="font-semibold underline">Tipos de cookies según la entidad que las gestione:</p><br/>
            <ul class="{boldListClass}">
                <li>
                    <span>Cookies propias: </span><span class="font-normal">son aquellas de las que es responsable el propio editor y que, generalmente, se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.</span>
                </li>
                <li>
                    <span>Cookies de terceros: </span><span class="font-normal">son aquellas de las que es responsable una entidad distinta del editor y que, generalmente, se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos a través de las cookies.</span>
                </li>
            </ul><br/>

            <p class="font-semibold underline">Tipos de cookies según su finalidad:</p><br/>
            <ul class="{boldListClass}">
                <li>
                    <span>Cookies técnicas: </span><span class="font-normal">son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan, incluyendo aquellas que el editor utiliza para permitir la gestión y operativa de la página web y habilitar sus funciones y servicios, como, por ejemplo, controlar el tráfico y la comunicación de datos, identificar la sesión, acceder a partes de acceso restringido,realizar el proceso de compra, realizar, almacenar contenidos, para la difusión de vídeos o sonido, habilitar contenidos dinámicos, etc.</span>
                </li>
                <li>
                    <span>Cookies de preferencias o personalización: </span><span class="font-normal">son aquellas que permiten recordar información para que el usuario acceda al servicio con determinadas características que pueden diferenciar su experiencia de la de otros usuarios, como, por ejemplo, el idioma, el número de resultados a mostrar cuando el usuario realiza una búsqueda, etc.</span>
                </li>
                <li>
                    <span>Cookies de análisis o medición: </span><span class="font-normal">son aquellas que permiten al responsable de las mismas el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas, incluida la cuantificación de los impactos de los anuncios.</span>
                </li>
                <li>
                    <span>Cookies de publicidad comportamental: </span><span class="font-normal">son aquellas que almacenan información del comportamiento de los usuarios obtenida a través de la observación continuada de sus hábitos de navegación, lo que permite desarrollar un perfil específico para mostrar publicidad en función del mismo.</span>
                </li>
            </ul><br/>

            <p class="font-semibold underline">Tipos de cookies según el plazo de tiempo que permanecen activadas:</p><br/>
            <ul class="{boldListClass}">
                <li>
                    <span>Cookies de sesión: </span><span class="font-normal">son aquellas diseñadas para recabar y almacenar datos mientras el usuario accede a una página web. Se suelen emplear para almacenar información que solo interesa conservar para la prestación del servicio solicitado por el usuario en una sola ocasión (por ejemplo, una lista de productos adquiridos) y desaparecen al terminar la sesión.</span>
                </li>
                <li>
                    <span>Cookies persistentes: </span><span class="font-normal">son aquellas en las que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un periodo definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.</span>
                </li>
            </ul><br/>

            <p>El portal del que es titular AV DRONE AND INSPECTION SL puede contener enlaces a sitios web de terceros, cuyas políticas de privacidad son ajenas a la de AV DRONE AND INSPECTION SL. Al acceder a tales sitios web usted puede decidir si acepta sus políticas de privacidad y de cookies. Con carácter general, si navega por internet usted puede aceptar o rechazar las cookies de terceros desde las opciones de configuración de su navegador. AV DRONE AND INSPECTION SL no se hace responsable, en ningún caso, ni del contenido ni de la veracidad de las políticas y/o condiciones de uso y privacidad de los terceros.</p>
        </div>

        <div class="mt-10">
            <h3 class="text-xl">Modificaciones. Actualización</h3>
            <p>La presente política de cookies puede verse modificada/actualizada en función de las exigencias legales establecidas o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia Española de Protección de Datos o, por la actualización del sitio web. Por esta razón, aconsejamos a los usuarios que visiten periódicamente nuestra política de cookies. </p><br/>
            <p>Si tiene dudas acerca de esta política de cookies, puede contactar con AV DRONE AND INSPECTION SL  a través del siguiente correo electrónico <a href="mailto: info@avdroneandinspection.com" class="{linkClass}">info@avdroneandinspection.com</a></p><br/>
            <p>Para obtener información adicional sobre el tratamiento de sus datos personales acuda a nuestra <a href="/area_privada/politica_privacidad" class="{linkClass}">Política de Privacidad</a></p>
        </div>
    </div>
</section>