

<script lang="ts">
    import {directusSDK } from "../../../api";
    import type { TechnicalInformation } from "../../../types/TechnicalInformation";
    import type { PartialItem } from "@directus/sdk";
    import { getImageUrl } from "../../../api";
    import { onMount } from "svelte";
    import Videos from "../../../components/Videos/Videos.svelte";
    import Images from "../../../components/Images/Images.svelte";
    import Files from "../../../components/Files/NovedadesFiles.svelte"
    import { addClassName, formatDate } from "../../../helpers";

    export let technicalId: number = null;
    let technical_info: PartialItem<TechnicalInformation>;

    onMount(async () => {
        addClassName()
        technical_info = await directusSDK.getItem("informacion_tecnica", technicalId, {fields: ['*', 'categoria.nombre', 'proyecto.nombre']});
    });
</script>

<style>
    :global(.technical_info-container .data-block) {
        margin-bottom: 15px;
    }
</style>

{#if technical_info}
    <div class="gap-4 p-4 inline-block my-5 technical_info-container">
        <div class="col-span-2 px-8 py-4 bg-white rounded-lg shadow-md data-block">
            {#if technical_info.imagen}
                <div class="w-100 h-100 float-left inline-block float-left pr-5">
                    <img src="{getImageUrl(technical_info.imagen,{w:400,h:300})}" alt="{technical_info.titulo + '-image'}">
                </div>
            {/if}
            <h2 class="mt-0">{technical_info.titulo}</h2>
            <div class="mb-3">
                <span class="text-sm font-light text-gray-600">{formatDate(technical_info.fecha)} 
                    {#if technical_info.proyecto}
                        <span class="technical_info-detail-project">{technical_info.proyecto.nombre}</span>
                    {/if}
                    {#if technical_info.categoria}
                        <span class="technical_info-detail-category">{technical_info.categoria.nombre}</span>
                    {/if}
                </span>
            </div>
            {#if technical_info.contenido}
                <div class="mb-3">                
                    {@html technical_info.contenido}                   
                </div>
            {/if}
            <div class="clear-both"></div>
        </div>
        <Videos id={technical_info.id} field= {'informacion_tecnica'} />
        <Images id={technical_info.id} field= {'informacion_tecnica'} />
        <Files {technical_info}/>
    </div>
{/if}
