<style lang="postcss">
    .login-card {
        @apply bg-gray-100;
        @apply rounded-lg;
        @apply shadow-xl;
        @apply border;
        @apply p-8;
    }
</style>

<script lang="ts">
  import {directusSDK} from '../api';
  import {onMount} from 'svelte';
  import Loading from './Loading.svelte'
  import {goto} from '@roxi/routify';

  let userName: string = '';

  let loading = false;

    onMount(() => {
        directusSDK.checkLogin();
    });

    async function handleOnSubmit() {
        loading = true;
            
        const url: string = `${window.location.origin}/cambiar_clave`;

        await directusSDK.changePassword(userName, url);
        
        loading = false
    }
</script>

<div class="grid place-items-center h-screen justify-center">
    <div class="login-card">
        <a href="/"><h1>AVDRONE</h1></a>
        <h3 class="text-center">Reestablecer mi clave de acceso</h3>
        <p class="text-center">
            Introduce tu email y te enviaremos instrucciones para reestablecer tu clave de acceso.
        </p>
        <form
                class="grid grid-rows gap-4"
                on:submit|preventDefault="{handleOnSubmit}"
                action="/"
        >
            <div class="form-group">
                <label for="username" class="form-control text-right">Email:</label>
                <input
                        id="username"
                        class="form-control"
                        type="email"
                        bind:value="{userName}"
                />
            </div>

            <button class="btn btn-primary flex flex-row" type="submit" disabled={loading}>
                <Loading classNames="{loading ? '':'hidden'}"/>
                Restablecer clave de acceso
            </button>
            <p class="text-center">
                <a on:click|preventDefault="{()=>$goto('/')}" href="/">
                    Acceder a pantalla de login
                </a>
            </p>
        </form>
    </div>
</div>
