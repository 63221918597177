<script lang="ts">
    import type { PartialItem} from '@directus/sdk';
    import type {Image} from '../../types/Image';
    import {getImageUrl} from '../../api';
    import { onMount } from 'svelte';

    export let image: PartialItem<Image>;

    function showPopUp()
    {   
        let popup = document.getElementById("image-popup-" + image.id);
        popup.style.display = "block";
    }

    function hidePopUp()
    {
        let popup = document.getElementById("image-popup-" + image.id);
        popup.style.display = "none";
    }

    function closePopUp(image: PartialItem<Image>, event: MouseEvent) {
        let popup = document.getElementById("image-popup-" + image.id);
        let imageDiv = document.getElementById("image-background-" + image.id);

        if (event.target == imageDiv) {
            popup.style.display = "none";
        }
    }

    async function toDataURL(url: string) {
        const blob = await fetch(url).then(res => res.blob());
        return URL.createObjectURL(blob);
    }

    async function download(url: string, title: string) {
        const a = document.createElement("a");
        a.href = await toDataURL(url);
        a.download = title;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    onMount(() => {
        window.addEventListener("click", closePopUp.bind(window.event, image));
    });
</script>

{#if image}
    <div class="max-w-sm rounded overflow-hidden bg-slate-100 shadow-lg">
        <img
                class="w-full p-2 border-slate-300"
                src="{getImageUrl(image.imagen.id.toString(),{w:400,h:300})}"
                alt="{image.description||''}"
        />
        <div class="px-6 py-4 text-center">
            <div class="font-bold text-xl mb-2">
                {image.description ||''}
            </div>
            <p class="text-gray-700 text-base">
                {new Date(image.fecha).toLocaleDateString('es-ES',{  year: 'numeric', month: 'long', day: 'numeric'} )}
            </p>
            <div class="flex flex-wrap flex-row justify-center mt-3">
                <button class="mr-1 px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80" on:click={ showPopUp }>
                    Ver
                </button>
                <button class="px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80 visited:text-white hover:text-white hover:no-underline"
                    on:click="{() => download(getImageUrl(image.imagen.id.toString()), image.imagen.title)}">
                    Descargar
                </button>
            </div>
        </div> 
        
    </div>

    <div id="image-popup-{ image.id }" class="hidden">
        <div id="image-background-{ image.id }" class="p-2 fixed w-full h-100 inset-0 z-50 overflow-hidden flex justify-center items-center bg-black bg-opacity-75">
            <div class="mt-3 text-center">

                <img
                class="mx-auto flex items-center justify-center"
                src="{getImageUrl(image.imagen.id.toString(),{w:800,h:600})}"
                alt="{image.imagen.description}"
                />
                <div class="my-4 text-sm text-white">
                    <p>Archivo: {image.imagen.filename_download}</p>
                    <p>Fecha: {new Date(image.fecha).toLocaleDateString('es-ES',{  year: 'numeric', month: 'long', day: 'numeric'} )}</p>
                </div>
                <div class="items-center px-4 py-3">
                    <button on:click={ hidePopUp }
                        class="px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 relative bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                    >
                        Volver
                    </button>
                </div>
            </div>
        </div>
    </div>
{/if}
