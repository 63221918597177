<script lang="ts">
    import {directusSDK } from "../../../api";
    import type { News } from "../../../types/News";
    import type { PartialItem } from "@directus/sdk";
    import { onMount } from "svelte";
    import { addClassName, formatDate } from "../../../helpers";
    import NewsDetail from "../../../components/News/NewsDetail.svelte";

    export let newsId: number = null;
    let news: PartialItem<News>;

    onMount(async () => {
        addClassName()
        news = await directusSDK.getItem("novedades", newsId, {fields: ['*', 'categoria.nombre', 'proyecto.nombre']});
    });
</script>

<style>
    :global(.news-container .data-block) {
        margin-bottom: 15px;
    }
</style>

<NewsDetail bgWhite={true} news={news}/>
