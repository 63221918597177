<script type="ts">
    import type {PartialItem} from "@directus/sdk";
    import Player from "@vimeo/player";
    import {directusSDK} from "../../api";
    import VideoCard from "../VideoCard/VideoCard.svelte";
    import type { Video } from "../../types/Video";
    import {onMount} from "svelte";

    export let id: number;
    export let field: string;
    export let bgWhite: boolean = true;

    let videos: Array<PartialItem<Video>> = [];

    let videoPlayer = null;

    onMount(async () => {
        videos = (await directusSDK.getItems("videos", {
            filter: {
                [field]: {_eq: id}
            },
        })).data;

        window.addEventListener("click", closePopUp.bind(window.event));
    })

    export function showPopUp(videoFromVimeo, video: PartialItem<Video>)
    {   
        const playerOptions = {
            id: videoFromVimeo.video_id,
            width: 800,
        };
    
        document.getElementById("video-title").innerText = videoFromVimeo.title;

        const date = new Date(video.fecha).toLocaleDateString('es-ES',{  year: 'numeric', month: 'long', day: 'numeric'} );
        document.getElementById("video-date").innerText = `Fecha: ${date}`;

        videoPlayer = new Player("vimeo_player", playerOptions);

        let popup = document.getElementById("vimeo-player-popup");
        popup.style.display = "block";

        videoPlayer.play();
    }

    function hidePopUp()
    {
        videoPlayer.destroy();

        let popup = document.getElementById("vimeo-player-popup");
        popup.style.display = "none";
    }

    function closePopUp(event: MouseEvent) {
        let popup = document.getElementById("vimeo-player-popup");
        let videoDiv = document.getElementById("vimeo-player-background");

        if (event.target == videoDiv) {
            videoPlayer.destroy();
            popup.style.display = "none";
        }
    }
</script>
{#if videos.length}
    <div class="{bgWhite ? 'bg-white shadow-md' : ''} col-span-2 px-8 py-4 rounded-lg data-block videos-block">
        <div class="flex items-center justify-between">
            <h1 class="text-3xl font-semibold text-gray-800 lg:text-4xl ">Vídeos</h1>
        </div>
        <div class="grid gap-4 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
            {#each videos as video }
                <VideoCard video={video} showPopUp={showPopUp}/>
            {/each}
            <div id="vimeo-player-popup" class="hidden">
                <div id="vimeo-player-background" class="p-2 fixed w-full h-100 inset-0 z-50 overflow-auto flex justify-center items-center bg-black bg-opacity-75">
                    <div class="mt-3 text-center">
        
                        <div class="mx-auto flex items-center justify-center"
                        >
                            <div id="vimeo_player"></div>
                        </div>
                        <div class="my-4 text-sm text-white">
                            <p id="video-title"></p>
                            <p id="video-date" class="mt-4"></p>
                        </div>
                        <div class="items-center px-4 py-3">
                            <button on:click={ hidePopUp }
                                class="px-4 py-2 font-medium tracking-wide text-white capitalize transition-colors duration-200 bg-blue-600 rounded-md hover:bg-blue-500 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-80"
                            >
                                Volver
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
{/if}