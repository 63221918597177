import clip from "text-clipper";
export function truncateHtml(html, length) {
    return clip(html, 200, { html: true });
}
export function addClassName() {
    let currentPage = '';
    const pathName = window.location.pathname;
    const parts = pathName.split('/');
    if (parts.length > 2 && !isNaN(Number(parts[parts.length - 1]))) {
        currentPage = parts[parts.length - 2] + '_detail_page';
    }
    else {
        currentPage = parts[parts.length - 1] + '_page';
    }
    document.body.setAttribute("class", currentPage);
}
export function formatDate(date) {
    return new Date(date).toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
}
