<style global lang="postcss">
    @import "./Pagination.css";
</style>

<script lang="ts">
    import {afterUpdate, createEventDispatcher, onMount} from 'svelte';

    export let total: number = 0;
    export let label: string = '';
    export let numPerPageOptions: Array<number> = [];

    let perPage: string = "";
    let lastPage: number =  Math.ceil(total / parseInt(perPage));
    let fromItem: number = 0;
    let toItem: number = 0;
    let currentPage: number = 0;

    const dispatch = createEventDispatcher();

    onMount(() => {
        perPage = numPerPageOptions[0].toString();     
        changePage(1);
    });

    afterUpdate(() => {
        lastPage = Math.ceil(total / parseInt(perPage));
    })

    function getPaginationRange(size: number) {
        return [...Array(size).keys()].map(i => i + 1);
    }

    function changePage(page: number) {
        let perPageInt: number = parseInt(perPage);
        currentPage = page;
        fromItem = (currentPage - 1) * perPageInt + 1;
        toItem = (currentPage) * perPageInt;
        lastPage = Math.ceil(total / perPageInt);


        dispatch('change', {page, perPage});
    }
</script>

{#if total}
    <div class="flex items-center justify-center mb-3 mt-5 pagination-container">
        <div class="flex pagination-select">
            <a href={"#"} on:click|preventDefault={() => currentPage > 1 && changePage(currentPage - 1)}
               class="{currentPage <= 1?'cursor-not-allowed opacity-30':''} flex items-center justify-center px-4 py-2 mx-1 text-gray-500 capitalize bg-white rounded-md  ">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clip-rule="evenodd"/>
                </svg>
            </a>
            {#each getPaginationRange(lastPage) as page}
                <a on:click|preventDefault={() => changePage(page)} href={"#"}
                   class="{page === currentPage ? 'bg-blue-500': ''} hidden px-4 py-2 mx-1 text-gray-700 transition-colors duration-200 rounded-md sm:inline   hover:bg-blue-500 :bg-blue-500 hover:text-white :text-gray-200">
                    {page}
                </a>

            {/each}
            <a on:click|preventDefault={() => currentPage !== lastPage && changePage(currentPage + 1)} href={"#"}
               class="{currentPage === lastPage?'cursor-not-allowed opacity-30':''} flex items-center justify-center px-4 py-2 mx-1 text-gray-500 capitalize bg-white rounded-md  ">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clip-rule="evenodd"/>
                </svg>
            </a>
        </div>
    </div>
    <div class="mt-6 mb-3 inline-block text-xs text-center w-full pagination-show">
        <div class="inline-block">
            <span>Mostrar:</span>
            <select name="per-page" class="form-control" bind:value={ perPage }
                    on:change={() => changePage(1)}>
                {#each numPerPageOptions as pageOption}
                    <option value={pageOption.toString()}>{pageOption}</option>
                {/each}
            </select>
            {label}
        </div>
    </div>
{/if}
  

