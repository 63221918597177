<style lang="postcss">
    .generic-card {
        @apply bg-gray-100;
        @apply rounded-lg;
        @apply shadow-xl;
        @apply border;
        @apply p-8;
    }
</style>

<script lang="ts">
    import { directusSDK } from '../../api';
    import { goto } from '@roxi/routify';
    import Header from '../../components/Header.svelte';
    import { onMount } from 'svelte';
    import { addClassName } from '../../helpers';

    onMount(() => addClassName());
</script>

<main>
    <div class="container mx-auto px-4">
        {#await directusSDK.getUser()}
            <Header />
            <div class="grid place-items-center h-screen justify-center">
                <div class="generic-card">Verificando credenciales...</div>
            </div>
        {:then user}
            <Header user="{user}" />
            <slot />
        {:catch error}
            {$goto('/')}
        {/await}

        <footer
            class="flex flex-col items-center justify-between px-6 py-4 bg-white  sm:flex-col"
        >
            <div
                class="flex flex-col justify-between py-4 sm:flex-row w-11/12"
            >
                <a
                    href="{'#'}"
                    class="text-xl font-bold text-gray-800  hover:text-gray-700 :text-gray-300"
                    >AVDRONE</a
                >

                <a
                    href="{'/area_privada/aviso_legal'}"
                    class="text-xl font-bold text-gray-800  hover:text-gray-700 :text-gray-300"
                    >Aviso legal</a
                >
                <a
                    href="{'/area_privada/politica_cookies'}"
                    class="text-xl font-bold text-gray-800  hover:text-gray-700 :text-gray-300"
                    >Política de cookies</a
                >
                <a
                    href="{'/area_privada/politica_privacidad'}"
                    class="text-xl font-bold text-gray-800  hover:text-gray-700 :text-gray-300"
                    >Política de privacidad</a
                >
            </div>

            <p class="py-2 text-gray-800  sm:py-0">
                © AVDRONE 2022 - Todos los derechos reservados
            </p>

            <div class="flex -mx-2"></div>
        </footer>
    </div>
</main>
