<script lang="ts">
    import { onMount } from "svelte";
    import {directusSDK} from "../../api";
    import { addClassName } from "../../helpers";

    let subject: string = "";
    let body: string = "";

    async function createContactRecord() {
        await directusSDK.addItem("contactos", {
            asunto: subject,
            mensaje: body,
        });

        reset();
    }

    function reset() {
        subject = "";
        body = "";
    }

    onMount(() => addClassName())
</script>


<section class="w-full max-w-4xl px-6 py-4 mx-auto bg-white rounded-md shadow-md  my-3">
    <h2 class="text-3xl font-semibold text-center text-gray-800 ">Contacto</h2>
    <p class="mt-3 text-center text-gray-600 ">Puede contactar con nosotros mediante los siguientes
        medios o mediante el formulario de contacto</p>

    <div class="grid grid-cols-1 gap-6 mt-6 sm:grid-cols-2 md:grid-cols-3">
        <a href={"#"}
           class="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 transform rounded-md  hover:bg-blue-200 :bg-blue-500">
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd"
                      d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z"
                      clip-rule="evenodd"/>
            </svg>

            <span class="mt-2">Madrid, España</span>
        </a>

        <a href="tel:+34677463059"
           class="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 transform rounded-md  hover:bg-blue-200 :bg-blue-500">
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2 3a1 1 0 011-1h2.153a1 1 0 01.986.836l.74 4.435a1 1 0 01-.54 1.06l-1.548.773a11.037 11.037 0 006.105 6.105l.774-1.548a1 1 0 011.059-.54l4.435.74a1 1 0 01.836.986V17a1 1 0 01-1 1h-2C7.82 18 2 12.18 2 5V3z"/>
            </svg>
            <span class="mt-2">677 463 059</span>
        </a>

        <a href="mailto:info@avdroneandinspection.com"
           class="flex flex-col items-center px-4 py-3 text-gray-700 transition-colors duration-200 transform rounded-md  hover:bg-blue-200 :bg-blue-500">
            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"/>
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"/>
            </svg>
            <span class="mt-2">info@avdroneandinspection.com</span>
        </a>
    </div>

    <div class="mt-6">
        <form on:submit|preventDefault={ createContactRecord }>
            <div class="items-center -mx-2 md:flex">
                <div class="w-full mx-2">
                    <label for="subject-input" class="block mb-2 text-sm font-medium text-gray-600 ">Asunto</label>

                    <input id="subject-input"
                           bind:value={subject} required name="asunto"
                           placeholder="Indique el asunto de su mensaje"
                           class="block w-full px-4 py-2 text-gray-700 bg-white border rounded-md    focus:border-blue-400 focus:ring-blue-300 :border-blue-300 focus:outline-none focus:ring focus:ring-opacity-40"
                           type="text">
                </div>
            </div>

            <div class="w-full mt-4">
                <label for="message-textarea" class="block mb-2 text-sm font-medium text-gray-600 ">Mensaje</label>

                <textarea id="message-textarea"
                        placeholder="Mensaje" bind:value={body} required
                        class="block w-full h-40 px-4 py-2 text-gray-700 bg-white border rounded-md    focus:border-blue-400 :border-blue-300 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-40"></textarea>
            </div>
            
            <div class="flex justify-center mt-6">

                <button type="submit" class="px-4 py-2 text-white transition-colors duration-200 transform bg-gray-700 rounded-md hover:bg-gray-600 focus:outline-none focus:bg-gray-600">
                    Enviar
                </button>
            </div>
        </form>
    </div>
</section>