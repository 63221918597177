<script lang="ts">
    import { onMount } from 'svelte';
    import LegalNotice from '../../components/LegalNotice.svelte';
    import { addClassName } from '../../helpers';
    import PrivacyPolicy from '../../components/PrivacyPolicy.svelte';

    onMount(() => addClassName())
</script>

<div class="container">
    <PrivacyPolicy />
</div>